'use strict';

// Waypoint plugin
import VueWaypoint from 'vue-waypoint';
Vue.use(VueWaypoint);


// Vue Resource
import VueResource from 'vue-resource'
Vue.use(VueResource);


// Story Grid Item Options
var storygridItem = {
  template: '<div class="story-grid__grid" id="story-grid__grid">\
  <div class="story-grid__story-item" v-for="story in stories" :key="story.id">\
    <a v-bind:href="story.url">\
    <figure class="story-grid__item-image bg-image">\
    <img :src="story.thumb" alt="">\
    </figure><!-- /.story-grid__item-image -->\
    <div :class="{showingauthor:story.showAuthor}" class="story-grid__item-wrap" key="2">\
      <div class="inner">\
      <h4 slot="storyDate" class="story-grid__item-date">{{story.date}}</h4><!-- /.story-grid__item-date -->\
      <h3 slot="storyTitle" class="story-grid__item-title">{{story.title}}</h3><!-- /.story-grid__item-title -->\
      <div class="story-grid__item-caption" v-html="story.storyPreview">\
      </div><!-- /.story-grid__item-caption -->\
      <span class="story-grid__item-link"></span>\
      </div>\
    </div><!-- /story-content -->\
    <div class="story-grid__item-author">\
    <div class="inner" >\
      authorinfo\
    </div><!-- /inner -->\
    </div><!-- /author info -->\
    </a>\
    <!-- <span class="story-grid__item-author-info" @click="toggleAuthor()">i</span> -->\
    </div><!-- /story-grid__item -->\
    </div><!-- /storygrid__grid -->',
  props: {
    stories: {},
  },
  data: function () {
    return {
      showAuthor : true
    }
  },
  methods: {
    toggleAuthor: function() {
      this.showAuthor = !this.showAuthor;
    },
  },
}

Vue.component('storygrid-item', storygridItem);






// Vue.js
new Vue({
  el: '#app',
  delimiters: ['${', '}'],
  data: {
    menuOpen: false,
    showHeader: false,
    stories: [],
    storiesSpinner: false,
    nextPage:0,
  }, // End Data
  components: {
    'storygrid' : storygridItem,
  },
  methods: {
    // Open and Close Mobile Menu
    toggleMenu: function toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    // Hide Header on scroll
    inHandler () {
      this.showHeader = false;
    },
    // Show header on Scroll
    outHandler () {
      this.showHeader = true;
    },
    // Load more stories via elementAPI
    loadMoreStories: function() {
      this.showSpinner = 1;
      this.$http.get('/api/stories.json?pg=' + this.nextPage).then(function(data) {
        this.stories = this.stories.concat(data.body.data);
        this.nextPage = this.nextPage + 1;
        if (data.body.meta.pagination.total_pages == data.body.meta.pagination.current_page)
        this.nextPage = 0;
        this.showSpinner = 0;
      });
    },
  }, // end Methods
  //Show initial story grid
  created: function() {
    this.nextPage = 1;
    this.$http.get('/api/stories.json?pg=' + this.nextPage).then(function(data) {
      this.stories = this.stories.concat(data.body.data);
      this.nextPage = this.nextPage + 1;
      if (data.body.meta.pagination.total_pages == data.body.meta.pagination.current_page)
      this.nextPage = 0;
      this.showSpinner = 0;
    });
  }

});


// Toggle Accordian Items
var toggleAccordians = document.getElementsByClassName("accordian__item");

for ( var i = 0; i < toggleAccordians.length; i++ ) {
  toggleAccordians[i].onclick = toggleClassAccordians;
}
function toggleClassAccordians(event) {var el = event.target;
  if ( -1 !== el.className.indexOf( "open" ) ) {
	  el.className = el.className.replace( " open", "" );
  } else if ( -1 !== el.className.indexOf( "accordian__item" ) ) {
    el.className += " open"  
  } else {
    return;
  }
}


// Browser detection -------------------------------
var browser_name = '',
isIE = /*@cc_on!@*/false || !!document.documentMode,
isEdge = !isIE && !!window.StyleMedia;
if(navigator.userAgent.indexOf("Chrome") != -1 && !isEdge)
{
    browser_name = 'chrome';
}
else if(navigator.userAgent.indexOf("Safari") != -1 && !isEdge)
{
    browser_name = 'safari';
}
else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
{
    browser_name = 'firefox';
}
else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
{
    browser_name = 'ie';
}
else if(isEdge)
{
    browser_name = 'edge';
}
else 
{
   browser_name = 'other-browser';
}
document.getElementById('body').classList.add(browser_name);




// Fix 100vh mobile jumping by setting pixel value to 100vh element ------------
// credit: http://www.davemolinero.com/2015/09/21/quick-fix-for-the-100vh-mobile-adress-bar-bug/

// if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
//   var h = document.getElementById('#hero').height();
//   document.getElementById('#hero').height(h);
// } // end mobile jumping fix
